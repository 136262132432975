import useMutation from 'libs/useMutation';
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { shopAccountTokenState } from 'states/shopAccountTokenState';
import { isDesktop, isAndroid} from 'react-device-detect';
import { ButtonBase } from '@mui/material';
import { openHelp } from 'util/openHelp';
import { setUserId } from '@amplitude/analytics-browser';
import axios from 'axios';
import { channleTalkMemberInfo } from 'states/channeltalk/memberInfo';
import Loading from '@components/snippets/Loading';
import getAsyncUserkey from 'util/getAsyncUserKey';
import moment from 'moment-timezone';
import { systemCheckPeriod } from 'constant/system';

interface IEnterProps{
    redirectUrl :string
}

const initInfluencer = async(uk:string) =>{

    const influencer = await axios.get( `/api/event/influencer?uk=${uk}`)

    return Object.keys(influencer.data).length ?? null
}

const Enter: NextPage<IEnterProps> = ({redirectUrl}) => {
    const router = useRouter();
    const [enter, { loading, data, error }] = useMutation<any>("/api/shopEnter")
    const [accountToken, setAccountToken] = useRecoilState(shopAccountTokenState)
    const [canNotDirect, setCanNotDirect] = useState<boolean>(false);
    const asyncDefaultEnter = async() => {
        const userKey = await getAsyncUserkey();
        if(userKey){
            enter({
                key: userKey,
                os: isDesktop ? 'PC' : isAndroid ? 'AOS' : 'IOS'
            })
            window.localStorage.setItem('key', userKey)
            window.localStorage.setItem('uk', userKey)
            const reversedUk  = userKey.split('=').join('').split('').reverse().join('')
            setUserId(reversedUk)
        } else {
            router.push("/login")
            return
        }
    }

    const asyncDefaultDataEnter = async() => {
        let key:string
        if (router.query.key) {
            key = router.query.key as string;
        } else {
            key = await getAsyncUserkey();
        }
        {/** ------------------------------- accessToken 제대로 안불러왔을 때, 발행api 재요청  ------------------------------- */}
        if ( key && router.isReady ) {
            if(data && data.status !== 200){
                const reCall = setTimeout(()=>{
                    enter({
                        key, 
                        os: isDesktop ? 'PC' : isAndroid ? 'AOS' : 'IOS'
                    })
                }, 2500)
                return ()=> clearTimeout(reCall)
            }
            if( data && data.result && data.userKey !== "" ) {
                // 진행하고자 하는 곳으로 이동한다
                setAccountToken(data.userKey);
                if (router.query.redirectUrl) {
                    let redirectUrl = router.query.redirectUrl as string;
                    //localhost:3000/enter?k=5&key=djJ6N1I0WlRmQVVkQ2NUYzVDZ2s2bWVoNi9zdmlrUXJKRU1FTVhWNnc0VT0=&redirectUrl=/shop/products?no=106807551%26header%3DN
                    let redirectArr =  router.asPath.split("redirectUrl=");
                    if (redirectArr.length > 0) {
                        router.push(`${decodeURIComponent(redirectArr[1])}`)
                    } else {
                        router.push(`${redirectUrl}`)
                    }
                } else if (router.query.callbackURL) {
                    let callbackURL = router.query.callbackURL as string;
                    router.push(`${callbackURL}`)
                } else {
                    router.push('/shop/products' + (router.query.k ? ('?k=' + router.query.k) : (router.query.v ? ('?v=' + router.query.v) : '')))
                }
            }
        }
    }
    useEffect(()=>{
        if (moment().tz('Asia/Seoul').isBetween(moment(systemCheckPeriod.start), moment(systemCheckPeriod.end))) {
            router.push('/systemCheck')
            return
        }

        if( router.isReady && router.query.key ) {
            let key = router.query.key as string;
            if(key){
                // 구매를 일으킨 후기작성자 init
                enter({
                    key,
                    os: isDesktop ? 'PC' : isAndroid ? 'AOS' : 'IOS'
                });
            }
            router.query.key && window.localStorage.setItem('key', router.query.key as string)
            router.query.uk && window.localStorage.setItem('uk', router.query.uk as string)
            // amplitude - set user id
            const reversedUk = key.split('=').join('').split('').reverse().join('')
            setUserId(reversedUk)
        } else {
            if ( router.isReady ) {
                asyncDefaultEnter();
            }
        }
        router.query.baby_idx && window.localStorage.setItem('baby_idx', router.query.baby_idx as string)
        router.query.family_idx && window.localStorage.setItem('family_idx', router.query.family_idx as string)
    },[router.query])

    useEffect(()=>{
        if (moment().tz('Asia/Seoul').isBetween(moment(systemCheckPeriod.start), moment(systemCheckPeriod.end))) {
            router.push('/systemCheck')
            return
        }

        if( data ) {
            asyncDefaultDataEnter();
        }
    },[data])
    // enter 페이지에서 5초 이상 머무르는 경우, accessToken을 읽지 못할 가능성이 있음 -> 문의하기 버튼 띄운다.
    useEffect(() => {
        const timerId = setTimeout(() => {
            setCanNotDirect(true)
        }, 5000);

        return () => clearTimeout(timerId)
    }, [])

    return (<>
        <div className="w-full h-[80vh] flex flex-col justify-center items-center">
            <Loading loading />

            {/* <div className={`fixed transition text-center` + ' ' + (canNotDirect ? 'opacity-1' : 'opacity-0')}>
                <div className="my-4">혹시 이 페이지에서 넘어갈 수 없나요?</div>
                <ButtonBase onClick={() => {
                    if (canNotDirect) {
                        openHelp();
                    }
                }}>
                    <b className="underline underline-offset-2">문의하러 가기</b>
                </ButtonBase>
            </div> */}
        </div>
    </>); 
}

export default Enter;
